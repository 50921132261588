import React, { Fragment, useState, useEffect } from 'react';
import { ReactDOM } from 'react-dom';
import { Chart } from '@antv/g2';
import { Column, Plot } from '@ant-design/plots'
import { Card, Row, Col } from 'antd';
import BarGraph from 'component/BarGraph';
import Api from 'services/Api';

function Graphs(props) {
  // console.log("mila mila", props)







if(props?.data?.length!==0)
{
  return (

    <Row className='scroll' style={{ display: 'flex', gap: 65, maxHeight: '600px',overflowY:'scroll' ,width: '100%'}}>
{ props?.data?.map((val,index)=>{
 let data=[{y:val?.one,x:"😒"},{y:val?.two, x:"😧"},{y:val?.three,x:'🤨'},{y:val?.four,x:"😃"},{y:val?.five,x:"😍"}]
 let title={y:"Number of Associates", x:"Rating"};
 let question=val?.question
//  for(let i=0;i<5;i++)
//  {
//  data.push({type:val?.ratingsData[i]?.rating, sales:val?.ratingsData[i]?.count})
//  }
//  console.log("zzzzzz",data)
 
            return(
          <Col span={10}>
<BarGraph data={data} graphHeight={350} barColor="#5E8CFC" question={question} title={title} date={props?.date} deptId={props?.deptId}></BarGraph>
</Col>
            )    
                
               
         })}
    </Row>

    )
  }
  else
  {
    return(
      <Row>No Data Found</Row>
    )
  }
}

export default Graphs
import React, { Fragment, useEffect, useState } from 'react';
import { LeftOutlined, RightOutlined, EyeOutlined } from '@ant-design/icons';
import { Input, Table, Row, Col, Modal, DatePicker, Spin, Typography } from 'antd';
import './Associates.css'
import Api from 'services/Api';
import moment from 'moment';
import 'antd/dist/antd.min.css'
import qs from 'qs';
import "../../App.css"
function Associates() {

    const time = moment().format("YYYY-MM");
    const [date, setDate] = useState(time);
    const [feedbackDate, setFeedbackDate] = useState(time);
    const [associateData, setAssociateData] = useState([])
    const [remarksModal, setRemarksModal] = useState(false);
    const [remarkData, setRemarkData] = useState([]);
    const [feedbackDataModal, setFeedBackDataModal] = useState(false)
    const [feedbackId, setfeedbackId] = useState(null);
    const [feedBackRemarkData, setFeedBackRemarkData] = useState({})
    const [recommendation,setRecommendation]=useState({})
    const [feedbackData,setFeedbackData]=useState([])
    const [offLocation,setOffLocation]=useState(null);
    const [meta,setMeta]=useState();
    const [search,setSearch]=useState("");
    const [loading,setLoading]=useState(false);
    const [loader,setLoader]=useState(false);
    const [loaderR,setLoaderR]=useState(false)
    const [tableParams, setTableParams] = useState({
        pagination: {
          current: 1,
          pageSize: 10,
        },
      });
      const getRandomuserParams = (params) => ({
        limit: params.pagination?.pageSize,
        page: params.pagination?.current,
        
      });
    useEffect(() => {
        setLoading(true)
        setLoader(true);
        // console.log("lll",qs.stringify(getRandomuserParams(tableParams)))
        Api.get(`/associates/associatesDetails/?${qs.stringify(getRandomuserParams(tableParams))}`).params({search:search}).send((res) => {
            // console.log("res/associates/associatesDetailres", res);
             setAssociateData(res?.responseData);
             setMeta(res?.meta);
             setLoading(false);
             setLoader(false);
             setTableParams({
                ...tableParams,
                pagination: {
                  ...tableParams.pagination,
                  total: res?.meta?.totalItems,  
                },
              });
        })
    }, [search, JSON.stringify(tableParams),date])
    const handleTableChange = (pagination, ) => {
        setTableParams({
          pagination,
          
        });
      };
    const { Search } = Input;


    const remarkHandler=(id, dept)=>{
        setLoaderR(true)
        let a=date?.split("-");
        // console.log("zzz",{month:a[1],year:a[0],department:dept ,empId:id})
        Api.get("/associates/remarkDetail/").params({month:a[1],year:a[0],department:dept ,empId:id}).send((res)=>{
            setLoaderR(false)
            // console.log("zzzz",res)
            setRecommendation(res);


        })
    }
    const feedbackHandler=(id, date)=>{
        setLoader(true)
        let a=date.split("-");
        Api.get("/departments/feedBackDetail/").params({month:a[1],year:a[0],empId:id}).send((res)=>{
            setLoader(false)
            setFeedbackData(res?.responseData);
            setOffLocation(res?.officeLocation)

        })
        setFeedBackDataModal(true);
    }

    const columns = [
        {
            title: 'Associate',
            dataIndex: 'associate',
            key: 'associate',
            render: (text, record) => {
                return (
                    <div onClick={() => {  setFeedBackRemarkData(record);setfeedbackId(record?.id); feedbackHandler(record?.id,feedbackDate)  }}>
                       
                            <Typography.Text ellipsis={{tooltip:true}} style={{font: 'normal normal medium 16px/21px Roboto', maxWidth:"150px",}}> {record?.empyName}</Typography.Text>
                    
                        <p style={{ font: 'normal normal normal 12px Roboto', color: '#000000', opacity: '1' }}>
                            {record?.empyId}</p>
                    </div>
                )
            }

        },
        {
            title: 'Contact Details',
            dataIndex: 'contact_details',
            key: 'contact',
            render: (text, record) => {
                return (
                    <div onClick={() => { setFeedBackRemarkData(record);setfeedbackId(record?.id); feedbackHandler(record?.id,feedbackDate)  }}>
                       <Typography.Text ellipsis={{tooltip:true}} style={{font: 'normal normal medium 16px/21px Roboto', maxWidth:"150px",}}>
                            {record?.empyEmail}</Typography.Text>
                        <p style={{ font: 'normal normal normal 16px/21px Roboto', color: '#000000', opacity: '1' }}>
                            {record?.empyPhoneNumber}</p></div>
                )
            }

        },

        {
            title: 'Designation',
            dataIndex: 'designation',
            key: "designation",
            render: (text, record) => {

                return (
                    <div onClick={() => {  setFeedBackRemarkData(record);setfeedbackId(record?.id); feedbackHandler(record?.id,feedbackDate) }}
                    style={{ font: 'normal normal normal 16px/21px Roboto', color: '#000000', opacity: '1' }}  >
                        {record?.empyDesignation}</div>
                )
            }

        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: "department",
            render: (text, record) => {

                return (
                    <div onClick={() => {setFeedBackRemarkData(record);setfeedbackId(record?.id); feedbackHandler(record?.id,feedbackDate) }}
                    style={{ font: 'normal normal normal 16px/21px Roboto', color: '#000000', opacity: '1' }} >
                        {record?.empyDepartment}</div>
                )
            }
        },
        {
            title: 'Team',
            dataIndex: 'team',
            key: "team",
            render: (text, record) => {

                return (
                    <div onClick={() => {  setFeedBackRemarkData(record);setfeedbackId(record?.id); feedbackHandler(record?.id,feedbackDate) }}
                        style={{ font: 'normal normal normal 16px/21px Roboto', color: '#000000', opacity: '1' }}>
                        {record?.teamName}</div>
                )
            }
        },
        {
            title: 'Manager',
            dataIndex: 'manager',
            key: "manager",
            render: (text, record) => {

                return (
                    <div onClick={() => {  setFeedBackRemarkData(record);setfeedbackId(record?.id);feedbackHandler(record?.id,feedbackDate) }}
                    style={{ font: 'normal normal normal 16px/21px Roboto', color: '#000000', opacity: '1' }} >
                        {record?.managerName}</div>
                )
            }
        },
        {
            title: 'Recommendations',
            dataIndex: 'recommendations',
            key: 'recommendation',
            render: (text, record) => {

                return (
                    <div style={{ font: 'normal normal normal 16px/21px Roboto', color: '#000000', opacity: '1' }}
                    onClick={() => {  remarkHandler(record?.id,record?.empyDepartment); setRemarksModal(true); setRemarkData(record) }} >
                        View Remarks <EyeOutlined />

                    </div>
                )
            }
        },
    ];
    const data = [];

    return (
        <div style={{ width: "100%" }}>

            <Row>
                <Row style={{ width: "100%" }}>
                    <Col span={2} offset={1} style={{ marginLeft: '3rem', marginTop: '3rem' }}>
                        <div style={{font:'normal normal bold 20px/28px Roboto',color:'#111111'}} ><h2>Associates</h2></div>
                    </Col>
                    <Col span={3} offset={14} style={{ marginTop: '3rem', marginLeft: '75%',  }}>
                        <DatePicker allowClear={false} defaultValue={moment(time, "YYYY/MM")} onChange={(date, datestring) => {
                            setDate(datestring);

                        }} picker="month" />
                    </Col>

                </Row>

                <Row style={{ width: "100%", marginLeft: '72%' }}>
                    <Col>
                        <Search className='btnSearch' placeholder="search" onChange={(val)=>{setSearch(val?.target?.value)}} style={{ height: '10%', width: 250}} />
                    </Col>
                </Row>
            </Row>


{/* {console.log("qqq",tableParams.pagination)} */}
            <section style={{ marginRight: "5rem"}} >
                <Table
                className='scroll'
                    columns={columns}
                    dataSource={associateData}
                    style={{ width: "100%", margin: '1rem', marginLeft: '3rem',minHeight:'800px',font:'Roboto'}} rowClassName='tableRow' 
        //             pagination={{
        //                 total:meta?.totalItems,
        //                 onShowSizeChange:{onShowSizeChange},
        //                 showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} members`
        //   }}
           pagination={tableParams.pagination}
           loading={loading}
          onChange={handleTableChange}
          scroll={{ y: 600 }}
          >

                </Table>

            </section>


            <Modal visible={remarksModal} onCancel={() => { setRemarksModal(false) }} footer={false} closable style={{ minWidth: '600px' }}>

                <Row >
                    <Row style={{ width: '100%' }}>
                        <Col span={6} offset={0}  >
                            <div className='empName'>{remarkData?.empyName}</div>

                        </Col>
                        <Col span={11} style={{ display: 'flex', gap: 5, }} >

                            <p>{remarkData?.empyEmail}</p>
                            <p>{remarkData?.empyPhoneNumber}</p>

                        </Col>
                        <Col span={4} offset={2}>

                            <div className='empName'>{remarkData?.empyDepartment}</div>



                        </Col>

                    </Row>
                    <Row style={{ width: "100%"}}>
                        <Col span={8}  >
                        <div >{remarkData?.empyId}</div>
                        </Col>
                        <Col span={10} offset={6} style={{display:'flex',gap:7}} >
                            <div className='empName'>{remarkData?.managerName}</div>
                            <div>{remarkData?.teamName}</div>
                        </Col>

                    </Row>
                    <Row className='scroll' style={{marginTop:"16px",maxHeight:'400px',overflowY:'scroll', overflowX:'unset', width:'100%' }}>
                    {loaderR?<Row justify="center"><Spin size="large" style={{ alignSelf: "center", minHeight:"10vh",margin:'5vh 0 0 10vw'}} /></Row>: <div style={{marginTop:'2rem'}}>{recommendation?.value}</div>}   
                       
                       

                    </Row>


                </Row>

            </Modal>



            <Modal visible={feedbackDataModal} onCancel={() => { setFeedBackDataModal(false) }} footer={false} closable style={{minWidth:'700px'}}>
                <Row >

                    <Row  style={{width:"100%"}}>
                        <Col span={10} style={{  }}>
                            
                                <div className='associateName'>{feedBackRemarkData?.empyName}</div>
                                <div >{feedBackRemarkData?.empyId}</div>
                                <div className='associateName'>{feedBackRemarkData?.empyDesignation}</div>
                            
                        </Col>


                        <Col span={11} offset={1} style={{ display: 'flex',gap:5 }} >
                       
                          <div style={{display:'flex',flexDirection:'column',gap:5, minWidth:'120px'}}>
                                <div>Department:</div>
                                <div>Manager:</div>
                                <div>Team:</div>
                                <div>Office Location:</div>
                                <div>Email ID:</div>
                                <div>Mobile Number:</div>
                         </div>
                         <div style={{display:'flex',flexDirection:'column',gap:5}}>                     
                            <div className='associateName'>{feedBackRemarkData?.empyDepartment?feedBackRemarkData?.empyDepartment:"------"}</div>
                            <div className='associateName'>{feedBackRemarkData?.managerName?feedBackRemarkData?.managerName:"-----"}</div>
                            <div className='associateName'>{feedBackRemarkData?.teamName?feedBackRemarkData?.teamName:"-----"}</div>
                            <div className='associateName'>{offLocation!==null?offLocation:"-----"}</div>
                            <div className='associateName'>{feedBackRemarkData?.empyEmail?feedBackRemarkData?.empyEmail:"-----"}</div>
                            <div className='associateName'>{feedBackRemarkData?.empyPhoneNumber?feedBackRemarkData?.empyPhoneNumber:"-----"}</div>
                           
                         </div>
                                      
                        </Col>
                    </Row>
                    <Row style={{ width: "100%", marginLeft: '18rem', marginTop:'20px' }}>
                        <Col span={8} offset={14} style={{display:'flex',alignItems:'center'}}>
                          <div className='datePicker'> <DatePicker defaultValue={moment(time, "YYYY/MM")} onChange={(date, datestring) => {
                               setFeedbackDate(datestring);
                               feedbackHandler(feedbackId,datestring)
                            }} picker="month" /></div>  
                        </Col>

                    </Row>
                    {loader?<Row justify="center"><Spin size="large" style={{ alignSelf: "center", minHeight:"10vh",margin:'5vh 0 0 10vw'}} /></Row>:
                    feedbackData?.length===0?<Row>No Data Found</Row>:
                    <Row className='scroll' style={{display:'flex',flexDirection:'row',gap:10, maxHeight:'500px',overflowY:'scroll',overflowX:'unset' }}>
                    {feedbackData?.map((val,index)=>{
                        const emoji=["","😒","😧",'🤨',"😃","😍"];
                            return(

                                <div style={{width:'100%'}}>
                                <div style={{backgroundColor:"#FAFAFA"}}>{val?.question}</div>
                                <div>{val?.data}</div>
                        </div>
                            )

                    })}

                </Row>
                   }

                   

                </Row>

            </Modal>






        </div>
    )
}

export default Associates
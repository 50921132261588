import React from 'react';
import { Layout, Card, Button, Row, Col, Tabs } from 'antd';
import { HomeOutlined, LogoutOutlined } from '@ant-design/icons';
import '../Dashboard/Dashboard.css';
import './Header.css'
import logout from "../../Images/LogoutIcon.svg"
function Header() {
    const { Header } = Layout;
    return (
        <Layout>
            <Header style={{
                background: 'white',
                font: 'normal normal normal 20px/23px Roboto',
                color: '#111111',
                opacity: '1',
                padding: '40px',
            }}>
                <Row>
                    <Col span={2} offset={1} >
                        {/* <div style={{marginLeft:'47px'}}><HomeOutlined /></div> */}
                    </Col>
                    <Col span={8} offset={1} >
                        {/* <div className='home'>HomePortal</div> */}
                    </Col>
                    <Col span={2} offset={2}>
                        {/* <div style={{marginLeft:'1px'}} onClick={()=>{localStorage.removeItem("token"); window.location.reload()}}><LogoutOutlined style={{ color: 'red' }} /> </div> */}
                    </Col>
                    <Col span={8} style={{display:"flex", gap:5}} onClick={()=>{localStorage.removeItem("token"); window.location.reload(); window.open("https://www.brigosha.com","_self")}} >
                        {/* <div><img src={logout}></img></div> */}
                        <Button className='logout' style={{font:"normal normal bold 16px/21px Roboto",padding:'5px 10px 5px 10px'}} >Logout</Button>
                    </Col>
                </Row> 

            </Header>


        </Layout>

    )
}
export default Header
// import Sider from "antd/lib/layout/Sider";
import React, { useEffect, useState } from 'react';
import { Menu, Layout, } from 'antd';
import brigoshalogo from '../../Images/brigoshalogo.png'
import { ClusterOutlined, UsergroupAddOutlined, AppstoreOutlined, } from '@ant-design/icons';
import '../Dashboard/Dashboard.css';
import { NavLink, useHistory } from "react-router-dom";
import "./Sidebar.css";


function Sidebar() {
    const { Sider } = Layout;
    const [menuKey,setMenuKey]=useState("1");
    let history = useHistory();
   
   
    useEffect(()=>{
        let href=window.location.pathname;
        if(href==="/")
        {
            setMenuKey("1")
        
        }
        
        else if(href==="/departments")
        {
            setMenuKey("2")
        }
        else if (href==="/associates")
        {
            setMenuKey("3")
        } 

    },[])
  


    const clickHandler=(key)=>{
    switch (key)
    {
    case "1":
        history.push("/")
        
        setMenuKey("1")
       

        break;
    case "2":
        history.push("/departments")
        setMenuKey("2")
      
        break;
    case "3":
        history.push("/associates")
        setMenuKey("3")
        break;
    }
 

        // console.log("kkkk",key)
    }









    // const dashboardHandler = () => {
    //     history.push("/")
    //     setMenuKey("1")

    // }
    // const departmentHandler = () => {
    //     history.push("/departments")
    //     setMenuKey("2")

    // }

    // const associateHandler = () => {
    //     history.push("/associates")
    //     setMenuKey("3")
    // }

    return (
       
            <div className='sideBar'>
                <div className='logo'>
                    <img
                        src={brigoshalogo}
                        alt="Brigosha Technologies"
                        style={{ width: 120, margin:'25px 0px 10px 26px' }}
                    />
                </div>
                
                <Menu
                    theme="white"
                    mode="inline"
                    // defaultSelectedKeys={menuKey}
                    selectedKeys={[menuKey]}
                    onClick={(key)=>{ clickHandler(key.key)}}
                    items={[
                        {
                            key: "1",
                            icon: <AppstoreOutlined />,
                            label: <div   style={{font:"normal normal normal 16px/21px Roboto", color:"#111111"}}>Dashboard</div>,
                        },
                        {
                            key: "2",
                            icon: <ClusterOutlined />,
                            label:<div  style={{font:"normal normal normal 16px/21px Roboto",color:"#111111",}}>Department</div>,
                        },
                        {
                            key: "3",
                            icon: <UsergroupAddOutlined />,
                            label:  <div style={{font:"normal normal normal 16px/21px Roboto",color:"#111111"}}>Associates</div>,
                        },
                    ]}
                  

                />

            </div>
        

    )
}
export default Sidebar;